<template>
  <div class="xy-load flex0">
      <div class="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="xy-load-title">播放器加载中...</div>
  </div>
</template>

<script>
export default {
  name: 'xyLoad',
  data() {
    return {}
  }
}
</script>

<style lang="scss">
  @import "./index.scss";
</style>