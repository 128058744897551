<template>
  <div class="LivePlayback">
    <div class="LivePlayback-content">
      <div class="head-crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div v-if="LivePlaybackPages.title" class="liveBack-title">
        {{ LivePlaybackPages.title }}
      </div>
      <div class="mb15 co-999" :class="clientWidth < 768 ? 'pl10' : ''"> {{ LivePlaybackPages.name }} {{ LivePlaybackPages.company }} </div>
      <div class="liveBackVideo" style="position: relative;">
        <xy-load v-if="liveLoad"></xy-load>
        <div v-if="LivePages.LivePlayStartState" class="live-play-btn flex0">
          <img src="@/assets/PC/fenceDetail/player.png" alt="" class="cur" @click="LivePlayStart">
        </div>
        <div v-if="LivePlaybackPages.videoUrl" id="LivePlay"></div>
        <div v-else class="live-content">
          <img :src="LivePages.LiveImage" alt="" width="100%">
        </div>
      </div>
      <div :class="clientWidth > 768 ? 'mt15' : 'mt10 ml10'">
        <el-button type="info" v-if="LivePlaybackPages.pdfFileUrl && LivePlaybackPages.type" @click="downLoad(LivePlaybackPages.pdfFileUrl)">文件下载</el-button>
        <el-button type="info" v-if="LivePlaybackPages.pdfFileUrl && !LivePlaybackPages.type" @click="downLoad(LivePlaybackPages.pdfFileUrl)">选手作品下载</el-button>
        <a v-if="LivePlaybackPages.mailto" class="el-button el-button--info co-fff" :href="'mailto:'+ LivePlaybackPages.mailto">联系选手</a>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import shadeAlert from "@/components/shadeAlert/index.vue";
import xyLoad from "@/components/xyLoad/index.vue";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { mapState } from 'vuex';

import { LivePlayBack, PlayBack } from '@/api/InnovationContest.js';
import { dataState } from "@/api/all.js";

export default {
  name: 'LivePlayback',
  data() {
    return {
      refer: [
        { name: "论剑 2021", href: "/Home/Live" },
        { name: "直播回放" },
      ],
      // !是否展示播放器模块
      liveLoad: true,
      // !直播间配置
      LivePages: {
        // @ 是否初始化直播间
        LivePlayer: false,
        // @ 是否正在直播
        isChannelLiving: false,
        // @ 直播封面图
        LiveImage: "",
        // @ 直播按钮
        LivePlayStartState: true
      },
      // !回放配置
      LivePlaybackPages: {
        coverUrl: '',
        title: '',
        videoUrl: ''
      },
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: "",
        text: "", // 弹窗内容
        btnType: "", // 按钮形式
        btnList: [],
      },
    }
  },
  mounted() {
    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "LivePlayback",
      relationId: this.$route.query.id,
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });

    if (!Mudu.Room) {
      this.GetLiveInit();
    } else {
      this.allInit();
    }
  },
  methods: {
    // !播放器按钮层
    LivePlayStart() {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来观看直播吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false
      }
      this.LivePages.LivePlayStartState = false;
      window._playerBack.play();
      PlayBack({id: this.$route.query.id}).then(res => {})
    },
    // !初始化直播组件
    GetLiveInit() {
      Mudu.Init(738798, () => {
        if (!this.LivePages.LivePlayer) {
          this.LivePages.LivePlayer = true;
          this.allInit();
        }
      });
    },
    // !初始化其他组件
    allInit() {
      LivePlayBack({agendaId: this.$route.query.id * 1}).then(res => {
        if (res.status === 1) {
          // @直播状态
          this.LivePlaybackPages = res.data
          this.LivePages.isChannelLiving = Mudu.Room.GetLiveStatus();
          // @直播间窗口封面
          this.LivePages.LiveImage = Mudu.Room.GetLiveImage();
          this.$nextTick(() => {
            this.GetPlayer();
          });
          this.liveLoad = false;
        } else {
          this.$toast(res.message);
          setTimeout(()=> {
            this.$router.push('/Home/Live')
          }, 500)
        }
      })
    },
    // !初始化直播窗口
    GetPlayer() {
      if (window._playerBack) {
        window._playerBack.remove();
        window._playerBack = null;
      }
      window._playerBack = new Mudu.Player({
        containerId: "LivePlay",
        isLive: false,
        src: this.LivePlaybackPages.videoUrl,
        image: this.LivePlaybackPages.coverUrl,
        autoplay: false,
        width: this.clientWidth > 750 ? 1200 : "100%",
        height: this.clientWidth > 750 ? 675 : "auto",
        x5Layer: false,
        x5Inline: false,
      });
    },
    // !下载选手作品pdf
    downLoad(url) {
      // 数据统计
    const data = {
      visitType: 2,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "LivePlayback",
      relationId: this.$route.query.id,
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });


      if(this.clientWidth > 768) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
  },
  computed: {
    ...mapState(['clientWidth', 'token'])
  },
  components: {
    xyLoad,
    breadcrumb,
    shadeAlert
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .LivePlayback .mudu-big-play-btn {
    display: none;
  }
  @media (max-width: 768px) {
    .LivePlayback #LivePlay {
      height: vw(425) !important;
    }
  }
</style>

<style lang="scss" scoped>
  @import './index.scss';
</style>